import { Inject, Injectable, Injector } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  interval,
  Observable,
  Subject,
  Subscription,
} from "rxjs";
import {
  DeviceStatus,
  ExternalDeviceEvent,
  FallEvent,
  FallEventStatus,
  DeviceEvent,
  PairedDevice,
  CaregiverAPI,
  DeviceAPI,
  DeviceInfo,
  PresenceIndication,
  DevicePresenceEvent,
  DeviceFallEvent,
  DeviceBedExitEventImminent,
  DeviceSensitiveFallEvent,
  SensitiveFallEvent,
  SensitiveFallEventStatus,
  DashboardConfig,
  DeviceConfigGen2,
  Room,
  AlertStatus,
  AlertResponse,
  AlertSource,
  DeviceAdminAPI,
  Suite,
  DeviceRoomConfig,
  DeviceHistoryAPI,
} from "@walabot-mqtt-dashboard/api";

import {
  AuthService,
  ConfigService,
  DeviceRoomConfigMapping,
  EventBusService,
  HistoryService,
  RoomsService,
  SuitesService,
  WebsocketService,
} from "./ui.module";
import {
  BedExitEventImminent,
  DeviceOfflineStatus,
  DeviceRoomDetails,
  DeviceState,
  DeviceStatusDetails,
  Environment,
  MessageData,
  MockableFallEvent,
  MockableSensitiveFallEvent,
  shouldFallNotificationAppear,
  SuiteAlert,
  SuiteDetails,
} from "./models";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuiteRoomMapping } from "./suites.service";
import { getAllDevicesInSuite, isPresenceAlertTimesActive } from "./utils";
import { DateTime } from "luxon";
import { HARDCODED_GO_LIVE_DATES } from "./alerts-history/alerts-history.component";
import { detectIncognito } from "detectincognitojs";
import firebase from "firebase/compat/app";

/**
 * Service responsible for monitoring devices and updating related data.
 * Handles initialization, configurations, and real-time event processing for devices.
 * Manages subscriptions to various data sources and updates the status maps as necessary.
 * Provides observables for device states, events, and presence indications.
 * Integrates with WebSocket services for real-time updates and notifications.
 */
@Injectable({
  providedIn: "root",
})
export class DeviceMonitoringService {
  ready = new BehaviorSubject(false);
  totalNumberOfDevices: number;
  simulatedFallEventDeviceId?: string;
  fallsCount = 0;
  disconnectedCount = 0;
  private _suiteStatusMap: { [suiteId: string]: SuiteDetails } = {};
  get suiteStatusMap() {
    return this._suiteStatusMap;
  }
  readonly deviceConfigMap: {
    [deviceId: string]: DeviceConfigGen2;
  } = {};
  roomMapping: SuiteRoomMapping;

  private _devicesStateMap: {
    [deviceId: string]: {
      state: DeviceState;
      updateTimestamp: number;
    };
  } = {};
  private _devicePresenceMap: {
    [deviceId: string]: {
      presenceIndication: PresenceIndication;
      updateTimestamp: number;
    };
  } = {};
  private _deviceFallMap: {
    [deviceId: string]: {
      eventType:
        | DeviceFallEvent.type.Fall
        | DeviceSensitiveFallEvent.type.SensitiveFall;
      fallEvent: FallEvent | SensitiveFallEvent;
      updateTimestamp: number;
    };
  } = {};
  private _deviceBedExitImminentMap: {
    [deviceId: string]: {
      bedExitImminentEvent: BedExitEventImminent;
      updateTimestamp: number;
    };
  } = {};
  private _externalDeviceMap: {
    [deviceId: string]: {
      externalDeviceEvent: ExternalDeviceEvent;
      updateTimestamp: number;
    };
  } = {};
  private _deviceStateMapSubject: BehaviorSubject<{
    [deviceId: string]: {
      state: DeviceState;
      updateTimestamp: number;
    };
  }>;
  private _presenceMapSubject: BehaviorSubject<{
    [deviceId: string]: {
      presenceIndication: PresenceIndication;
      updateTimestamp: number;
    };
  }>;
  private _fallMapSubject: BehaviorSubject<{
    [deviceId: string]: {
      eventType:
        | DeviceFallEvent.type.Fall
        | DeviceSensitiveFallEvent.type.SensitiveFall;
      fallEvent: FallEvent | SensitiveFallEvent;
      updateTimestamp: number;
    };
  }>;
  private _bedExitImminentMapSubject: BehaviorSubject<{
    [deviceId: string]: {
      bedExitImminentEvent: BedExitEventImminent;
      updateTimestamp: number;
    };
  }>;
  private _externalDeviceMapSubject: BehaviorSubject<{
    [deviceId: string]: {
      externalDeviceEvent: ExternalDeviceEvent;
      updateTimestamp: number;
    };
  }>;
  private _pairedDevicesSubject: BehaviorSubject<PairedDevice[]>;
  private _statePoller: Subscription;
  private _deviceRoomMapping: DeviceRoomConfigMapping = {};
  private messageSubscription: Subscription;

  public _rawEventSubject: Subject<{
    deviceId: string;
    event: DeviceEvent;
  }>;

  public _roomEventSubject: Subject<{
    roomId: string;
    event: { alarmDismissed: boolean };
  }> = new Subject();

  get rawEventsObservable(): Observable<{
    deviceId: string;
    event: DeviceEvent;
  }> {
    return this._rawEventSubject.asObservable();
  }

  get roomEventsObservable(): Observable<{
    roomId: string;
    event: { alarmDismissed: boolean };
  }> {
    return this._roomEventSubject.asObservable();
  }

  get deviceStateMap(): Observable<{
    [deviceId: string]: {
      state: DeviceState;
      updateTimestamp: number;
    };
  }> {
    return this._deviceStateMapSubject.asObservable();
  }

  get presenceObservable(): Observable<{
    [deviceId: string]: {
      presenceIndication: PresenceIndication;
      updateTimestamp: number;
    };
  }> {
    return this._presenceMapSubject.asObservable();
  }

  get fallsObservables(): Observable<{
    [deviceId: string]: {
      eventType:
        | DeviceFallEvent.type.Fall
        | DeviceSensitiveFallEvent.type.SensitiveFall;
      fallEvent: FallEvent | SensitiveFallEvent;
      updateTimestamp: number;
    };
  }> {
    return this._fallMapSubject.asObservable();
  }

  get bedExitImminentObservables(): Observable<{
    [deviceId: string]: {
      bedExitImminentEvent: BedExitEventImminent;
      updateTimestamp: number;
    };
  }> {
    return this._bedExitImminentMapSubject.asObservable();
  }

  get devices() {
    return this._pairedDevicesSubject.asObservable();
  }

  alerts: Array<AlertResponse> = [];

  private generalConfig: DashboardConfig;
  private deviceRoomMap: { [deviceId: string]: string } = {};
  private fallMapping: {
    [deviceId: string]: {
      eventType:
        | DeviceFallEvent.type.Fall
        | DeviceSensitiveFallEvent.type.SensitiveFall;
      fallEvent: MockableFallEvent | MockableSensitiveFallEvent;
      updateTimestamp: number;
    };
  } = {};
  private webSocketTokenRefreshTimeout: number;

  constructor(
    private authService: AuthService,
    @Inject("environment") private environment: { [key: string]: Environment },
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private roomsService: RoomsService,
    private webSocketService: WebsocketService,
    private deviceConfigService: ConfigService,
    private suitesService: SuitesService,
    private injector: Injector,
    @Inject("shouldFallNotificationAppear")
    private shouldFallNotificationAppear: shouldFallNotificationAppear
  ) {
    this.initializeSubjects();
    this.setupAuthSubscription();
    this.setupConfigSubscription();
    this.setupDataSubscriptions();
  }

  /**
   * Initializes BehaviorSubjects and Subjects.
   */
  private initializeSubjects(): void {
    this._deviceStateMapSubject = new BehaviorSubject({});
    this._presenceMapSubject = new BehaviorSubject({});
    this._fallMapSubject = new BehaviorSubject({});
    this._bedExitImminentMapSubject = new BehaviorSubject({});
    this._externalDeviceMapSubject = new BehaviorSubject({});
    this._pairedDevicesSubject = new BehaviorSubject<PairedDevice[]>(null);
    this._rawEventSubject = new Subject();
    this._statePoller = interval(
      <number>this.environment.deviceStatusCheckRate
    ).subscribe(() => {
      if (this._pairedDevicesSubject.value?.length) {
        void this.getDeviceState();
      }
    });
  }

  /**
   * Sets up subscription for authentication-related data.
   */
  private setupAuthSubscription(): void {
    if (this.auth && this.auth.currentUser && !window.frameElement) {
      this.auth.currentUser.subscribe((user) => {
        if (user && user.user) {
          this.roomsService.deviceMapping.subscribe((deviceRoomMapping) => {
            this._deviceRoomMapping = deviceRoomMapping;
          });
        }
      });
    }
  }

  /**
   * Sets up subscription for configuration data.
   */
  private setupConfigSubscription(): void {
    this.deviceConfigService.getDashboardConfig().subscribe((appConfig) => {
      this.generalConfig = appConfig;
    });
  }

  /**
   * Sets up subscriptions for various data sources and initializes the service.
   */
  private setupDataSubscriptions(): void {
    combineLatest([
      this.suitesService.suiteList,
      this.suitesService.roomMapping,
      this.roomsService.roomList,
      this.roomsService.deviceMapping,
      this.devices,
    ]).subscribe(([suiteList, roomMapping, rooms, deviceMapping, devices]) => {
      if (!rooms || !devices) {
        return;
      }
      if (rooms.length <= 0 || Object.keys(deviceMapping).length <= 0) {
        this.ready.next(true);
        return;
      }
      this.processRoomAndDeviceData(
        suiteList,
        roomMapping,
        rooms,
        deviceMapping,
        devices
      );
      if (this.environment.applicationId === "fall-detector") {
        void this.getDeviceFalls();
      }
      this.updateAlertsCount();
      this.ready.next(true);
    });
  }

  /**
   * Processes room and device data to update internal mappings.
   */
  private processRoomAndDeviceData(
    suiteList: Suite[],
    roomMapping: SuiteRoomMapping,
    rooms: any[],
    deviceMapping: DeviceRoomConfigMapping,
    devices: PairedDevice[]
  ): void {
    this.roomMapping = roomMapping;
    const deviceMap: { [key: string]: PairedDevice } = {};
    devices.forEach((device) => {
      deviceMap[device.id] = device;
    });
    this.updateSuiteStatusMap(suiteList, roomMapping);
    this.updateDeviceStatusDetails(deviceMapping, deviceMap);
    this.updateDeviceConfigs(deviceMapping);
  }

  /**
   * Updates the suite status map with new data.
   */
  private updateSuiteStatusMap(
    suiteList: Suite[],
    roomMapping: SuiteRoomMapping
  ): void {
    const newSuiteStatusMap: { [suiteId: string]: SuiteDetails } = {};
    suiteList.forEach((suite) => {
      newSuiteStatusMap[suite.id] = {
        name: suite.name,
        id: suite.id,
        rooms: {},
        alert: {
          isPresented: false,
          isFallActive: false,
          isBedExitActive: false,
        },
      };
      (roomMapping[suite.id] || []).forEach((room) => {
        newSuiteStatusMap[suite.id].rooms[room.id] = {
          ...room,
          type: room.type,
          devices: {},
        };
      });
    });
    this._suiteStatusMap = newSuiteStatusMap;
  }

  /**
   * Updates device status details and mappings.
   */
  private updateDeviceStatusDetails(
    deviceMapping: DeviceRoomConfigMapping,
    deviceMap: { [key: string]: PairedDevice }
  ): void {
    Object.values(deviceMapping).forEach((deviceRoomConfig) => {
      const deviceStatus = this.createDeviceStatus(deviceRoomConfig, deviceMap);
      this.updateSuiteStatusMapWithDevice(deviceStatus, deviceRoomConfig);
      this.deviceRoomMap[deviceRoomConfig.deviceId] = deviceRoomConfig.roomId;
    });
    this.totalNumberOfDevices = Object.keys(deviceMapping).length;
  }

  /**
   * Creates a device status object.
   */
  private createDeviceStatus(
    deviceRoomConfig: DeviceRoomConfig,
    deviceMap: { [key: string]: PairedDevice }
  ): DeviceStatusDetails {
    const deviceStatus: DeviceStatusDetails = {
      pairedDeviceDetails: deviceMap[deviceRoomConfig.deviceId],
      deviceId: deviceRoomConfig.deviceId,
      roomId: deviceRoomConfig.roomId,
      isFallActive: false,
      isBedExitActive: false,
      isPresenceDetected: false,
      presenceRegions: {},
      deviceStatus: DeviceStatus.INITIALIZING,
      roomType: deviceRoomConfig.type,
    };

    if (deviceRoomConfig.deviceId in this._devicesStateMap) {
      deviceStatus.deviceStatus =
        this._devicesStateMap[deviceRoomConfig.deviceId].state.status;
      deviceStatus.deviceWifiState =
        this._devicesStateMap[deviceRoomConfig.deviceId].state.wifiState;
    }
    if (deviceRoomConfig.roomId) {
      const suiteId = Object.values(this.roomMapping)
        .flat()
        .find((room) => {
          return room.id === deviceRoomConfig.roomId;
        })?.suiteId;
      if (this.suiteStatusMap[suiteId]) {
        this.suiteStatusMap[suiteId].rooms[deviceRoomConfig.roomId].devices[
          deviceStatus.deviceId
        ] = deviceStatus;
      }
    }

    return deviceStatus;
  }

  /**
   * Updates the suite status map with device information.
   */
  private updateSuiteStatusMapWithDevice(
    deviceStatus: DeviceStatusDetails,
    deviceRoomConfig: DeviceRoomConfig
  ): void {
    const suiteId = Object.values(this.roomMapping)
      .flat()
      .find((room) => {
        return room.id === deviceRoomConfig.roomId;
      })?.suiteId;
    if (
      suiteId &&
      deviceRoomConfig.roomId &&
      this.suiteStatusMap[suiteId].rooms[deviceRoomConfig.roomId].devices[
        deviceStatus.deviceId
      ]
    ) {
      this.suiteStatusMap[suiteId].rooms[deviceRoomConfig.roomId].devices[
        deviceStatus.deviceId
      ].deviceStatus = deviceStatus.deviceStatus;
      this.suiteStatusMap[suiteId].rooms[deviceRoomConfig.roomId].devices[
        deviceStatus.deviceId
      ].deviceWifiState = deviceStatus.deviceWifiState;
    }
  }

  /**
   * Updates device configurations.
   */
  private updateDeviceConfigs(deviceMapping: DeviceRoomConfigMapping): void {
    Object.values(deviceMapping).forEach((deviceRoomConfig) => {
      this.deviceConfigService
        .getConfig(deviceRoomConfig.deviceId)
        .then((deviceConfig) => {
          this.deviceConfigMap[deviceRoomConfig.deviceId] = deviceConfig;
        })
        .catch((err: string) => {
          console.error(`Get device config failed: ${err}`);
        });
    });
  }

  /**
   * Registers a WebSocket connection for real-time device monitoring.
   * @param baseURL The base URL for the WebSocket connection.
   */
  public async registerWebSocket(baseURL: string) {
    if (!firebase.auth().currentUser) {
      return;
    }
    this.closeWebSocket();
    // receive messages
    this.messageSubscription = this.webSocketService.messageSubject.subscribe(
      (message: { [key: string]: any }) => {
        if (message) {
          if (message?.type && message?.payload) {
            const deviceId =
              <string>message.deviceId ??
              <string>(<{ [key: string]: any }>message.payload).deviceId;
            this.handleDeviceEvents(deviceId, <DeviceEvent>message);
          } else if (
            message?.notification &&
            (<{ [key: string]: any }>message?.data)?.type === "roomEvent"
          ) {
            this.handleRoomEvents(message);
          } else if (!(<{ [key: string]: any }>message?.data)?.status) {
            console.log(
              `\nvayyar_debug [discard] unknown type message: ${JSON.stringify(
                message
              )}`
            );
          }
        }
      },
      (err) =>
        console.log("\nvayyar_debug websocket Service messageSubject", err)
    );

    const token = await this.authService.getToken(true);
    const tokenResult = await firebase.auth().currentUser.getIdTokenResult();
    this.webSocketService.connect(
      `wss://${baseURL.replace("https://", "")}/websocket?token=${token}`
    );

    // Show permission UI.
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      await this.showNoNotificationAccessWarning();
      console.log("Unable to get permission to notify.");
    }

    const expTime = new Date(tokenResult.expirationTime).getTime();
    const now = Date.now();
    const timeLeft = expTime - now;
    /**
     * Use new Firebase ID token for WebSocket connection after previous token expired.
     */
    this.webSocketTokenRefreshTimeout = window.setTimeout(
      (() => this.registerWebSocket(baseURL)) as () => void,
      timeLeft
    );
  }

  /**
   * Closes the current WebSocket connection.
   */
  public closeWebSocket() {
    console.log("\nvayyar_debug websocket try close WebSocket .....");
    // Unsubscribe
    if (
      typeof this.messageSubscription !== "undefined" &&
      this.messageSubscription != null
    ) {
      this.messageSubscription.unsubscribe();
      this.messageSubscription = null;
      console.log("\nvayyar_debug websocket unsubscribe messageSubscription!");
    }
    this.webSocketService.destoryWebSocket();
  }

  /**
   * Deletes a bed exit event for a specific device.
   * @param deviceId The ID of the device.
   */
  public deleteBedExitEvent(deviceId: string) {
    delete this._deviceBedExitImminentMap[deviceId];
    this._bedExitImminentMapSubject.next(this._deviceBedExitImminentMap);
  }

  private async getDeviceFalls() {
    const deviceIDs = this._pairedDevicesSubject.value.map(
      (device) => device.id
    );
    return Promise.allSettled(
      deviceIDs.map((deviceID) => DeviceHistoryAPI.getHistoryFall(deviceID))
    ).then((results) => {
      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          if (this.shouldFallNotificationAppear(result.value)) {
            this.handleFallReportEvent(deviceIDs[index], {
              type: DeviceFallEvent.type.Fall,
              payload: result.value,
            });
          }
        }
      });
    });
  }

  /**
   * Handles incoming messages, typically used for mocking events.
   * @param payload The message payload.
   * @param background Whether the message is received in the background.
   */
  public handleMessage(payload: MessageData, background = false) {
    if (payload.data && payload.data.event) {
      const event = JSON.parse(payload.data.event) as DeviceEvent;
      const deviceId = payload.data.deviceId;
      const eventDescription: { [key: string]: any } = {};
      switch (payload.data.type) {
        case "event":
          this.handleDeviceEvents(deviceId, event);
          eventDescription["Event Type"] = `Device Event`;
          switch (event.type) {
            case DevicePresenceEvent.type.Presence:
              eventDescription["Device Event Type"] = "Presence";
              eventDescription["Presence Detected"] =
                event.payload.presenceDetected;
              break;
            case DeviceFallEvent.type.Fall:
              eventDescription["Device Event Type"] = "Fall";
              eventDescription["Fall Status"] = event.payload.status;
              break;
            case DeviceSensitiveFallEvent.type.SensitiveFall:
              eventDescription["Device Event Type"] = "Sensitive Fall";
              eventDescription["Sensitive Fall Status"] = event.payload.status;
              break;
            case DeviceBedExitEventImminent.type.BedExitImminent:
              eventDescription["Device Event Type"] = "BedExit";
              break;
          }
          eventDescription["Device ID"] = deviceId;
          break;
        case "roomEvent":
          this.handleRoomEvents(payload);
          eventDescription["Event Type"] = `Room Event`;
          eventDescription["Room ID"] = payload.data.roomId;
          break;
      }
      eventDescription["Payload"] = structuredClone(event);
      console.group(
        `GOT${
          background ? " BACKGROUND " : " "
        }FCM MESSAGE: ${new Date().toLocaleString()}`
      );
      console.table([eventDescription]);
      console.groupCollapsed("Raw message");
      console.log(structuredClone(payload));
      console.groupEnd();
      console.groupEnd();
    }
  }

  /**
   * Handles device events based on the event type and application ID.
   * @param deviceId The ID of the device.
   * @param event The device event.
   */
  private handleDeviceEvents(deviceId: string, event: DeviceEvent): void {
    if (!event.type) return;
    if (event.type === DevicePresenceEvent.type.Presence) {
      this.handlePresenceEvent(deviceId, event.payload);
    } else {
      switch (this.environment.applicationId) {
        case "fall-detector":
          this.handleFallDetectorEvents(deviceId, event);
          break;
        case "bed-exit-detector":
          this.handleBedExitDetectorEvents(deviceId, event);
          break;
      }
    }
  }

  /**
   * Handles events specific to fall detector devices.
   * @param deviceId The ID of the device.
   * @param event The device event.
   */
  private handleFallDetectorEvents(deviceId: string, event: DeviceEvent): void {
    switch (event.type) {
      case DeviceFallEvent.type.Fall:
      case DeviceSensitiveFallEvent.type.SensitiveFall: {
        if (!this.shouldFallNotificationAppear(event.payload)) return;
        if (this.handleFallReportEvent(deviceId, event)) {
          this._rawEventSubject.next({ deviceId, event });
        }
      }
    }
  }

  /**
   * Handles events specific to bed exit detector devices.
   * @param deviceId The ID of the device.
   * @param event The device event.
   */
  private handleBedExitDetectorEvents(
    deviceId: string,
    event: DeviceEvent
  ): void {
    switch (event.type) {
      case DeviceBedExitEventImminent.type.BedExitImminent:
        if (isPresenceAlertTimesActive(this.generalConfig)) {
          this.handleBedExitEvent(deviceId, event.payload);
          this._rawEventSubject.next({ deviceId, event: event });
        }
        break;
      case ExternalDeviceEvent.type.ExternalDevice: {
        this.handleExternalDeviceEvent(deviceId, event);
        break;
      }
    }
  }

  /**
   * Handles room events, such as alarm dismissals.
   * @param payload The message data containing room event information.
   */
  private handleRoomEvents(payload: MessageData) {
    const e: { alarmDismissed: boolean } = JSON.parse(payload.data.event) as {
      alarmDismissed: boolean;
    };
    if (e.alarmDismissed) {
      Object.values(this._deviceRoomMapping)
        .filter((deviceRoomConfig) => {
          return deviceRoomConfig.roomId === payload.data.roomId;
        })
        .forEach((deviceRoomConfig) => {
          const { eventType, fallEvent } =
            this._deviceFallMap[deviceRoomConfig.deviceId];
          fallEvent.dismissed = true;
          this._deviceFallMap[deviceRoomConfig.deviceId] = {
            eventType,
            fallEvent,
            updateTimestamp: Date.now(),
          };
          const existedAlert = this.alerts.find(
            (a) => new Date(a.createdAt).getTime() === fallEvent.timestamp
          );
          if (existedAlert) {
            this.alerts.splice(this.alerts.indexOf(existedAlert), 1);
          }
        });
      this.updateFallMapping();
      this._fallMapSubject.next(this._deviceFallMap);
      this._roomEventSubject.next({
        roomId: payload.data.roomId,
        event: {
          alarmDismissed: true,
        },
      });
    }
  }

  /**
   * Handles presence events for a device.
   * @param deviceId The ID of the device.
   * @param payload The presence indication payload.
   * @returns The processed presence indication.
   */
  private handlePresenceEvent(
    deviceId: string,
    payload: PresenceIndication
  ): PresenceIndication {
    this._devicePresenceMap[deviceId] = {
      presenceIndication: payload,
      updateTimestamp: Date.now(),
    };
    const presenceMap = this._devicePresenceMap;
    if (presenceMap) {
      Object.keys(presenceMap).forEach((deviceId) => {
        const roomId = this.getRoomForDevice(deviceId);
        const suiteId = Object.values(this.roomMapping)
          .flat()
          .find((room) => {
            return room.id === roomId;
          })?.suiteId;
        if (
          suiteId &&
          roomId &&
          this.suiteStatusMap[suiteId].rooms[roomId].devices[deviceId]
        ) {
          const presence = presenceMap[deviceId];
          this.suiteStatusMap[suiteId].rooms[roomId].devices[
            deviceId
          ].isPresenceDetected = presence.presenceIndication.presenceDetected;
          this.suiteStatusMap[suiteId].rooms[roomId].devices[
            deviceId
          ].presenceRegions = presence.presenceIndication.presenceRegionMap;
          this.setAlertStatusForSuite(this.suiteStatusMap[suiteId]);
          return;
        }
      });
    }
    this._presenceMapSubject.next(this._devicePresenceMap);
    return payload;
  }

  /**
   * Handles fall report events for a device.
   * @param deviceId The ID of the device.
   * @param event The fall event or sensitive fall event.
   * @returns A boolean indicating whether the event was processed.
   */
  private handleFallReportEvent(
    deviceId: string,
    event: DeviceFallEvent | DeviceSensitiveFallEvent
  ) {
    if (this.isFallActive(event.payload)) {
      this._deviceFallMap[deviceId] = {
        eventType: event.type,
        fallEvent: event.payload,
        updateTimestamp: Date.now(),
      };
      const eventBus = this.injector.get(EventBusService);
      const { roomName, suiteName } = eventBus.getRoomAndSuiteName(deviceId);
      this.alerts.unshift({
        alertId: event.payload.eventId,
        deviceId,
        createdAt: new Date(event.payload.timestamp).toISOString(),
        source:
          event.type === DeviceFallEvent.type.Fall
            ? AlertSource.Fall
            : AlertSource.SensitiveFall,
        sourceTime: new Date(event.payload.timestamp).toISOString(),
        status: AlertStatus.Active,
        roomName,
        suiteName,
      });
      this.updateFallMapping();
      this._fallMapSubject.next(this._deviceFallMap);
      return true;
    }

    return false;
  }

  /**
   * Updates the fall mapping and related statuses.
   */
  private updateFallMapping() {
    const fallMapping = this._deviceFallMap;
    if (fallMapping) {
      Object.keys(fallMapping).forEach((deviceId) => {
        const roomId = this.getRoomForDevice(deviceId);
        const suiteId = Object.values(this.roomMapping)
          .flat()
          .find((room) => {
            return room.id === roomId;
          })?.suiteId;
        if (
          suiteId &&
          roomId &&
          this.suiteStatusMap[suiteId].rooms[roomId].devices[deviceId]
        ) {
          const fallEvent = fallMapping[deviceId],
            isAlreadyPresented =
              this.suiteStatusMap[suiteId].rooms[roomId].devices[deviceId]
                .isFallActive && this.getAlertForSuite(suiteId).isPresented,
            isFallActive = this.isFallActive(fallEvent.fallEvent);
          this.suiteStatusMap[suiteId].rooms[roomId].devices[
            deviceId
          ].isFallActive = isFallActive;
          this.setAlertStatusForSuite(this.suiteStatusMap[suiteId]);
          if (
            isFallActive &&
            this.getAlertForSuite(suiteId).isPresented &&
            !isAlreadyPresented
          ) {
            this.playAudio().catch((err) => {
              console.log(err);
            });
          }
        }
        if (
          this.fallMapping?.[deviceId]?.fallEvent.isMock &&
          !(<MockableFallEvent | MockableSensitiveFallEvent>(
            fallMapping[deviceId]?.fallEvent
          )).isMock
        ) {
          (<MockableFallEvent | MockableSensitiveFallEvent>(
            fallMapping[deviceId].fallEvent
          )).isReal = true;
          (<MockableFallEvent | MockableSensitiveFallEvent>(
            fallMapping[deviceId].fallEvent
          )).isMock = false;
        }
      });
    }
    this.fallMapping = structuredClone(fallMapping);
    this.updateFallsCount();
  }

  /**
   * Checks if a fall event is active.
   * @param fallEvent The fall event to check.
   * @returns A boolean indicating whether the fall event is active.
   */
  private isFallActive(fallEvent: FallEvent | SensitiveFallEvent) {
    return (
      [
        FallEventStatus.Calling,
        FallEventStatus.Finished,
        SensitiveFallEventStatus.Calling,
        SensitiveFallEventStatus.Finished,
      ].includes(fallEvent.status) && !fallEvent.dismissed
    );
  }

  /**
   * Handles bed exit events for a device.
   * @param deviceId The ID of the device.
   * @param payload The bed exit event payload.
   * @returns The processed bed exit event.
   */
  private handleBedExitEvent(
    deviceId: string,
    payload: BedExitEventImminent
  ): BedExitEventImminent {
    this._deviceBedExitImminentMap[deviceId] = {
      bedExitImminentEvent: payload,
      updateTimestamp: Date.now(),
    };
    const bedExitImminentMapping = this._deviceBedExitImminentMap;
    if (bedExitImminentMapping) {
      Object.keys(bedExitImminentMapping).forEach((deviceId) => {
        const roomId = this.getRoomForDevice(deviceId);
        const suiteId = Object.values(this.roomMapping)
          .flat()
          .find((room) => {
            return room.id === roomId;
          })?.suiteId;
        if (
          suiteId &&
          roomId &&
          this.suiteStatusMap[suiteId].rooms[roomId].devices[deviceId]
        ) {
          /**
           * BedExit app doesn't have it's own API for alert time configuration
           * So we use the same API as in Facility app.
           */
          this.suiteStatusMap[suiteId].rooms[roomId].devices[
            deviceId
          ].isBedExitActive = true;
          const isAlreadyPresented = this.getAlertForSuite(suiteId).isPresented;
          this.setAlertStatusForSuite(this.suiteStatusMap[suiteId]);
          if (
            this.getAlertForSuite(suiteId).isPresented &&
            !isAlreadyPresented
          ) {
            this.playAudio().catch((err) => {
              console.log(err);
            });
          }
          return;
        }
      });
    }
    this.updateFallsCount();
    this._bedExitImminentMapSubject.next(this._deviceBedExitImminentMap);
    return payload;
  }

  /**
   * Handles external device events.
   * @param deviceId The ID of the device.
   * @param payload The external device event payload.
   * @returns The processed external device event.
   */
  private handleExternalDeviceEvent(
    deviceId: string,
    payload: ExternalDeviceEvent
  ): ExternalDeviceEvent {
    this._externalDeviceMap[deviceId] = {
      externalDeviceEvent: payload,
      updateTimestamp: Date.now(),
    };
    const buttonMapping = this._externalDeviceMap;
    if (buttonMapping) {
      Object.keys(buttonMapping).forEach((deviceId) => {
        const roomId = this.getRoomForDevice(deviceId);
        const suiteId = Object.values(this.roomMapping)
          .flat()
          .find((room) => {
            return room.id === roomId;
          })?.suiteId;
        if (
          suiteId &&
          roomId &&
          this.suiteStatusMap[suiteId].rooms[roomId].devices[deviceId]
        ) {
          if (
            buttonMapping[deviceId].externalDeviceEvent.type ===
            ExternalDeviceEvent.type.ExternalDevice
          ) {
            this.cancelBedExitAlarm(suiteId);
          }
        }
      });
    }
    this._externalDeviceMapSubject.next(this._externalDeviceMap);
    return payload;
  }

  /**
   * Retrieves the list of paired devices.
   * @param loadInitialStatus Whether to load the initial status of devices.
   * @returns A promise resolving to an array of PairedDevice objects.
   */
  public async getPairedDevices(
    loadInitialStatus?: boolean
  ): Promise<PairedDevice[]> {
    return DeviceAPI.getPairedDevices().then((devices) => {
      this._pairedDevicesSubject.next(devices);
      if (loadInitialStatus) {
        const length = devices ? devices.length : 0;
        if (length > 0) {
          //TODO: ASK| These promises aren't handled, affect the homepage devices, await each promises => no login.
          // Promise.all([promises]) => Devices constant loading.
          void this.getDeviceState();
        } else {
          this._deviceStateMapSubject.next({});
        }
      }
      return devices;
    });
  }

  /**
   * Retrieves the current state of all devices.
   */
  private async getDeviceState() {
    const uid = this.authService.getUser().uid;
    return DeviceAdminAPI.getPagingForCaregiverAssignedResources(
      uid,
      "v3"
    ).then((res1) => {
      return Promise.all(
        new Array(res1.pagination.totalPages - 1).fill(null).map((_, i) => {
          return DeviceAdminAPI.getPagingForCaregiverAssignedResources(
            uid,
            "v3",
            res1.pagination.pageSize,
            i + 2
          );
        })
      )
        .then((res) => {
          return res1.suites.concat(...res.flatMap((r) => r.suites));
        })
        .then((suites) => {
          suites.forEach((suite) => {
            suite.rooms.forEach((room) => {
              room.devices.forEach((device) => {
                let status: DeviceStatus | DeviceOfflineStatus;
                const wifiState = {
                  ssid: device.ssid,
                  rssi: device.rssi,
                  bssid: device.bssid,
                };
                if (device.status === "offline") {
                  status = DeviceOfflineStatus.DISCONNECTED;
                } else {
                  status = device.status;
                }
                this._devicesStateMap[device.deviceId] = {
                  state: {
                    deviceId: device.deviceId,
                    timestamp: device.timestamp,
                    upTime: device.timestamp,
                    status,
                    wifiState,
                  },
                  updateTimestamp: device.timestamp,
                };

                if (this.roomMapping) {
                  if (
                    this.suiteStatusMap[suite.suiteId]?.rooms[room.roomId]
                      ?.devices[device.deviceId]
                  ) {
                    this.suiteStatusMap[suite.suiteId].rooms[
                      room.roomId
                    ].devices[device.deviceId].deviceStatus = status;
                    this.suiteStatusMap[suite.suiteId].rooms[
                      room.roomId
                    ].devices[device.deviceId].deviceWifiState = wifiState;
                  }
                }
              });
            });
          });

          this._deviceStateMapSubject.next(this._devicesStateMap);
          this.updateDisconnectedCount();
        });
    });
  }

  /**
   * Retrieves information about a specific device.
   * @param deviceId The ID of the device.
   * @returns A promise resolving to the DeviceInfo object.
   */
  public getDeviceInfo(deviceId: string): Promise<DeviceInfo> {
    return DeviceAPI.getDevice(deviceId);
  }

  /**
   * Unpairs a device from the system.
   * @param deviceId The ID of the device to unpair.
   * @returns A promise resolving to the response from the unpairing operation.
   */
  public async unpair(deviceId: string) {
    const uid = this.authService.getUser().uid;
    return CaregiverAPI.unlinkDevice(uid, deviceId).then(async (response) => {
      delete this._deviceFallMap[deviceId];
      delete this._devicePresenceMap[deviceId];
      delete this._devicesStateMap[deviceId];
      this._deviceStateMapSubject.next(this._devicesStateMap);
      await this.getPairedDevices(false);
      return response;
    });
  }

  /**
   * Clears all stored device data and resets the service state.
   */
  clear() {
    this._pairedDevicesSubject.next(null);
    this._devicesStateMap = {};
    this._devicePresenceMap = {};
    this._deviceFallMap = {};
    this._deviceStateMapSubject.next({});
    this._presenceMapSubject.next({});
    this._fallMapSubject.next({});
    this._bedExitImminentMapSubject.next({});
    this._externalDeviceMapSubject.next({});
    this.roomsService.clear();
    this.suitesService.clear();
    this._suiteStatusMap = {};
    clearTimeout(this.webSocketTokenRefreshTimeout);
    this.ready.next(false);
  }

  /**
   * Displays an error message using a snackbar.
   * @param msg The error message to display.
   */
  showErrorMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 3000,
      horizontalPosition: "left",
      panelClass: ["success-msg", "error"],
    });
  }

  /**
   * Displays a warning message with more information using a snackbar.
   * @param msg The warning message to display.
   */
  showWarningMoreInfoMsg(msg: string) {
    this.snackBar.open(msg, "X", {
      duration: 300000,
      horizontalPosition: "left",
      panelClass: ["success-msg", "warning", "more-info"],
    });
  }

  /**
   * Retrieves the alert status for a specific suite.
   * @param suiteId The ID of the suite.
   * @returns The SuiteAlert object for the specified suite.
   */
  getAlertForSuite(suiteId: string): SuiteAlert {
    const suite = this.suiteStatusMap[suiteId];
    if (suite) {
      return this.suiteStatusMap[suite.id].alert;
    } else {
      return {
        isFallActive: false,
        isPresented: false,
        isBedExitActive: false,
      };
    }
  }

  /**
   * Dismisses a fall alert for a specific suite and room.
   * @param suiteId The ID of the suite.
   * @param roomId The ID of the room.
   * @param fallEventDismissFeedBack Feedback for dismissing the fall event.
   * @returns A promise that resolves when the dismissal is complete.
   */
  dismissFallAlert(
    suiteId: string,
    roomId: string,
    fallEventDismissFeedBack: string
  ) {
    const suite = this.suiteStatusMap[suiteId];
    const allDevices = getAllDevicesInSuite(suite, roomId);
    return Promise.allSettled(
      allDevices.map((device) => {
        if (device.isFallActive) {
          const roomId = this.getRoomForDevice(device.deviceId);
          const eventType: "fall" | "sensitiveFall" =
            this.fallMapping[device.deviceId].eventType ===
            DeviceFallEvent.type.Fall
              ? "fall"
              : "sensitiveFall";
          return this.deviceConfigService
            .dismissFallAlert(roomId, eventType, fallEventDismissFeedBack)
            .catch((error) => {
              console.error("Dismiss fall alert failed: ", error);
            })
            .finally(() => {
              if (this.simulatedFallEventDeviceId === device.deviceId) {
                this.simulatedFallEventDeviceId = null;
              }
              if (this.fallMapping[device.deviceId].fallEvent.isMock) {
                this.handleMessage({
                  data: {
                    type: "roomEvent",
                    roomId,
                    event: JSON.stringify({
                      alarmDismissed: true,
                    }),
                  },
                });
              }
              device.isFallActive = false;
            });
        }
      })
    ).finally(() => {
      this.setAlertStatusForSuite(suite);
      this.updateFallsCount();
    });
  }

  /**
   * Cancels a bed exit alarm for a specific suite.
   * @param suiteId The ID of the suite.
   */
  cancelBedExitAlarm(suiteId: string) {
    const suite = this.suiteStatusMap[suiteId];
    const allDevices = getAllDevicesInSuite(suite);
    Promise.all(
      allDevices.map((device) => {
        if (device.isBedExitActive) {
          this.deviceConfigService
            .cancelAlarm(device.deviceId)
            .catch((error) => {
              console.error("Cancel alarm failed: ", error);
            })
            .finally(() => {
              device.isBedExitActive = false;
              suite.alert.isPresented = false;
              this.deleteBedExitEvent(device.deviceId);
              this.updateFallsCount();
            });
        }
      })
    ).catch((error) => {
      suite.alert.isPresented = false;
      console.error("Cancel alarm failed: ", error);
    });
  }

  /**
   * Updates room information in the internal mapping.
   * @param room The updated Room object.
   */
  updateRoomInfoInMapping(room: Room) {
    const roomToUpdate = this.suiteStatusMap[room.suiteId].rooms[room.id];

    roomToUpdate.name = room.name;
    roomToUpdate.type = room.type;
  }

  /**
   * Loads alerts from the history service.
   */
  loadAlerts() {
    const historyService = this.injector.get(HistoryService);
    void historyService
      .getAlerts({
        fromCreatedAt: DateTime.local().minus({ hour: 24 }).toISO(),
        toCreatedAt: DateTime.local().toISO(),
      })
      .then((alerts) => {
        this.alerts = alerts.filter((alert) =>
          [AlertStatus.Active, AlertStatus.Taken].includes(alert.status)
        );
      });

    void this.getPastSixMonthsNotTodayUnresolvedAlerts();
  }

  /**
   * Gets the room associated with a specific device.
   * @param deviceId The ID of the device.
   * @returns The ID of the room associated with the device.
   */
  private getRoomForDevice(deviceId: string) {
    return this.deviceRoomMap[deviceId];
  }

  /**
   * Updates the count of alerts in the system.
   */
  private updateAlertsCount() {
    this.updateFallsCount();
    this.updateDisconnectedCount();
  }

  /**
   * Updates the count of disconnected devices.
   */
  private updateDisconnectedCount() {
    const eventBus = this.injector.get(EventBusService);
    this.disconnectedCount = Object.values(this.suiteStatusMap)
      .filter((suite) => !suite.alert.isPresented)
      .map((suite) => getAllDevicesInSuite(suite))
      .flat()
      .filter(
        (deviceDetails) =>
          deviceDetails.deviceStatus === DeviceOfflineStatus.DISCONNECTED
      ).length;
    eventBus.disconnectedCount.emit(this.disconnectedCount);
  }

  /**
   * Updates the count of fall events.
   */
  private updateFallsCount() {
    const eventBus = this.injector.get(EventBusService);
    this.fallsCount = Object.values(this.suiteStatusMap)
      .map((suite) => getAllDevicesInSuite(suite))
      .flat()
      .filter(
        (deviceDetails) =>
          deviceDetails.isFallActive || deviceDetails.isBedExitActive
      ).length;
    eventBus.fallCount.emit(this.fallsCount);
  }

  /**
   * Sets the alert status for a specific suite.
   * @param suiteDetails The SuiteDetails object to update.
   */
  private setAlertStatusForSuite(suiteDetails: SuiteDetails) {
    if (suiteDetails) {
      const alert: SuiteAlert = {
        isFallActive: false,
        isBedExitActive: false,
        isPresented: false,
      };
      const allDevices = getAllDevicesInSuite(suiteDetails);
      for (const device of allDevices) {
        if (device.deviceStatus !== DeviceOfflineStatus.DISCONNECTED) {
          if (device.isFallActive) {
            alert.isFallActive = true;
          }
          if (device.isBedExitActive) {
            alert.isBedExitActive = true;
          }
        }
      }
      alert.isPresented = alert.isBedExitActive || alert.isFallActive;
      this.suiteStatusMap[suiteDetails.id].alert = alert;
    }
  }

  /**
   * Plays an audio notification.
   */
  private async playAudio() {
    const audio = new Audio();
    audio.src = "assets/chime.mp3";
    audio.load();
    await audio.play();
  }

  /**
   * Retrieves unresolved alerts from the past six months, excluding the current day.
   */
  public getPastSixMonthsNotTodayUnresolvedAlerts() {
    const historyService = this.injector.get(HistoryService);
    let fromCreatedAt = DateTime.local().minus({ month: 6 }).toJSDate();
    if (this.auth.currentUser?.value.user.uid in HARDCODED_GO_LIVE_DATES) {
      fromCreatedAt = new Date(
        Math.max(
          +HARDCODED_GO_LIVE_DATES[this.auth.currentUser.value.user.uid],
          +fromCreatedAt
        )
      );
    }
    void historyService
      .getAlerts({
        fromCreatedAt: fromCreatedAt.toISOString(),
        toCreatedAt: DateTime.local().minus({ hour: 24 }).toISO(),
        statuses: [AlertStatus.Active, AlertStatus.Taken],
        limit: Number.MAX_SAFE_INTEGER,
      })
      .then((alerts) => {
        this.updateUnresolvedAlertsCount(alerts.length);
      });
  }

  /**
   * Updates the count of unresolved alerts.
   * @param unresolvedAlertsCount The new count of unresolved alerts.
   */
  private updateUnresolvedAlertsCount(unresolvedAlertsCount: number) {
    const eventBus = this.injector.get(EventBusService);

    eventBus.unresolvedAlertsCountValue = unresolvedAlertsCount;
  }

  /**
   * Displays a warning if browser notifications are not enabled.
   */
  private async showNoNotificationAccessWarning() {
    const isInPrivacyMode = await this.isInPrivacyMode();
    if (isInPrivacyMode) {
      console.warn("You are in an Incognito Window.");
      return;
    }

    this.snackBar.open(
      $localize`:@@enable-browser-notification-warning:Please enable notifications in your browser to receive dashboard alerts and status changes.`,
      "X",
      {
        horizontalPosition: "left",
        panelClass: ["success-msg", "warning"],
      }
    );
  }

  /**
   * Checks if the browser is running in incognito or privacy mode.
   * @returns A promise resolving to a boolean indicating privacy mode status.
   */
  public async isInPrivacyMode() {
    try {
      const result = await detectIncognito();
      console.log("detectIncognito", result);
      return result.isPrivate;
    } catch (err) {
      console.warn("detectIncognito failed", err);
      return false;
    }
  }
}
