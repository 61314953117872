<div class="container page">
  <h1 i18n="@@events-history">Events History</h1>
  <span class="facility-name">{{getUserNameAndEmail(user).displayName}} <span class="apartment-number">({{suitesNumber}} <span i18n="@@apartments">Apartments</span>)</span></span>
  <div style="padding-bottom: 20px;">
  <div class="filter">
    <div class="custom-select-div">
      <span i18n="@@apartment">Apartment</span>
      <mat-select
        [(ngModel)]="selectedSuites"
        multiple
        (openedChange)="onMultiSelectSelectionOpened($event)"
        (ngModelChange)="selectedSuiteChanged($event)"
        panelClass="custom-select suite-select-panel"
        data-cy="suite-select"
      >
        <mat-option (click)="toggleSelectAllSuites($event)" [value]="SelectAllSuites" i18n="@@select-all" data-cy="suite-select-all">
          Select All
        </mat-option>
        <!-- (click)="toggleSelectUnassignedDevices($event)" -->
        <mat-option *ngIf="unassignedDevices.size > 0" [value]="SelectUnassignedDevices" data-cy="suite-unassigned-devices">
          {{ getTotalNumberOfUnassignedDevicesText() }}
        </mat-option>
        <mat-option 
          *ngFor="let suite of suiteMapping | keyvalue: valueAscOrder"
          [value]="suite.key"
          [disabled]="selectedSuites.length > 9 && !selectedSuites.includes(suite.key)"
          [attr.data-cy]="suite.key"
        >
          {{ suite.value.name }}
        </mat-option>
      </mat-select>
    </div>
    <div class="custom-select-div dt-select-div">
      <span i18n="@@time-and-date">Time & Date</span>
      <mat-select
        #dateSelector
        [(ngModel)]="selectedRange"
        name="timeRange"
        (ngModelChange)="onTimeRangeChange($event)"
        panelClass="custom-select"
        data-cy="date-time-select"
      >
        <div #customTimeOption></div>
        <mat-option
          *ngFor="let timeRange of timeRanges"
          [value]="timeRange.value"
          [attr.data-cy]="'date-time-value-' + timeRange.value"
        >
          {{ timeRange.name }}
        </mat-option>
        <mat-option [value]="customTimeRange" class="custom-time-range">
          <div
            style="border-top: 2px solid #e3e3e3"
            (click)="openCustomTimeRange($event)"
          >
            <span i18n="@@custom-range"> Custom Range </span>
            <div class="arrow-icon">
              <img
                src="../assets/icons/arrow.svg"
              />
            </div>
          </div>
          <input
            #dateTimeTrigger
            style="display: none"
            [owlDateTime]="dt1"
            [selectMode]="'range'"
            [owlDateTimeTrigger]="dt1"
            [min]="minValidDateTime"
            [max]="maxValidDateTime"
            placeholder="Date Time"
            i18n-placeholder="@@date-time-placeholder"
            (dateTimeInput)="onSelectedCustomDate($event)"
          />
          <owl-date-time [panelClass]="'pickerCls'" #dt1> </owl-date-time>
        </mat-option>
      </mat-select>
    </div>
    <div class="custom-select-div">
      <span i18n="@@resolution">Resolution</span>
      <mat-select
              [(ngModel)]="selectedResolutions"
              multiple
              (openedChange)="onMultiSelectSelectionOpened($event)"
              (ngModelChange)="selectedResolutionChanged($event)"
              panelClass="custom-select resolution-select-panel"
              data-cy="resolution-select"
      >
        <mat-option (click)="toggleSelectAllResolutions($event)" [value]="SelectAllResolutions" i18n="@@select-all" data-cy="resolution-select-all">
          Select All
        </mat-option>
        <ng-container *ngFor="let resolution of resolutionMap | keyvalue: valueAscOrder">
          <mat-option *ngIf="resolution.key !== ResolutionType.LEARNING"
                  [value]="resolution.key"
                  [attr.data-cy]="resolution.key"
          >
            {{ resolution.value.name }}
          </mat-option>
        </ng-container>
        <mat-option [value]="UnresolvedResolution" i18n="@@unresolved">
          Unresolved
        </mat-option>
      </mat-select>
    </div>
    <div class="custom-select-div">
      <button *ngIf="unresolvedAlertsCount > 0" mat-stroked-button type="button" class="unresolved-alerts-btn" [class.filter-btn]="filterUnresolvedAlerts" (click)="onFilterUnresolvedAlerts()">
        <span class="unresolved-alerts-btn-title">
          <span data-cy="unresolved-alerts-btn-count">{{ unresolvedAlertsCount }} </span>
          <span i18n="@@unresolved-alerts">Unresolved Alerts</span>
          <div class="icon-container">
            <img *ngIf="!filterUnresolvedAlerts" src="../../assets/icons/filter.svg">
            <mat-icon *ngIf="filterUnresolvedAlerts">close</mat-icon>
          </div>
        </span>
        <mat-hint i18n="@@range-last-6-months">Last 6 Months</mat-hint>
      </button>
    </div>
    <button mat-stroked-button type="button" class="btn btn-secondary download" (click)="downloadCSV()">
      <span class="material-icons material-symbols-outlined">output</span>
      <span i18n="@@export">Export</span>
    </button>
  </div>
    <p class="report-dates"><ng-container i18n="@@dates-of-report">Dates of Report</ng-container>: {{selectedFrom | date: dateTimeFormat}} - {{selectedTo | date: dateTimeFormat}}</p>
  <table
    mat-table
    matSort
    class="event-table"
    [dataSource]="eventsDataSource"
    data-cy="events-table"
    (contentChanged)="onTableContentChanged()"
  >
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@time-and-date">
        Time & Date
      </th>
      <td mat-cell *matCellDef="let element" [attr.data-timestamp]="element.timestamp">
        <div>{{ element.timestamp | date: dateTimeFormat }} {{zoneAbbr}}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="apartment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@apartment">
        Apartment
      </th>
      <td mat-cell *matCellDef="let element" [attr.data-suite-id]="element.suiteId">
        <span style="vertical-align: middle">{{ element.apartment }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="roomType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@room-type">
        Room Type
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="vertical-align: middle">{{ element.roomTypeName }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="roomName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@room-name">
        Room Name
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="vertical-align: middle">{{ element.roomName }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="deviceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@device-id">
        Device ID
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="vertical-align: middle">{{ element.deviceId }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@event-type">
        Event Type
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="vertical-align: middle;">{{ element.sourceName }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="resolution">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@resolution">
        Resolution
      </th>
      <td mat-cell *matCellDef="let element">
        <span data-cy="resolution-value">{{ element.resolution }}</span>
        <button *ngIf="isAdmin && element.resolution" mat-button class="edit-resolution" (click)="openPopover(popover, true)" i18n="@@edit">
          EDIT
        </button>
        <button *ngIf="isAdmin && !element.resolution" mat-button class="add-resolution" (click)="openPopover(popover)" i18n="@@resolve">
          Resolve
        </button>
        <div class="popover" #popover>
          <div>
            <mat-radio-group [ngModel]="element.lastResolutionType" #group="ngModel">
              <mat-radio-button data-cy="without-injury" i18n="@@confirmed-fall-without-injury" labelPosition="before" [value]="ResolutionType.CONFIRMED_WITHOUT_INJURY">
                Confirmed fall without injury
              </mat-radio-button>
              <mat-radio-button data-cy="with-injury" i18n="@@confirmed-fall-with-injury" labelPosition="before" [value]="ResolutionType.CONFIRMED_WITH_INJURY">
                Confirmed fall with injury
              </mat-radio-button>
              <mat-radio-button data-cy="no-fall" i18n="@@no-fall" labelPosition="before" [value]="ResolutionType.UNCONFIRMED">
                No Fall
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="buttons">
            <button mat-stroked-button (click)="closePopover(popover)">Cancel</button>
            <button data-cy="save-resolution" mat-stroked-button class="save" [disabled]="!group.dirty" (click)="changeResolution(element, group.value); closePopover(popover)">
              Save
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastResolutionUpdatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@resolutionUpdatedAt">
        Date & Time of Resolution
      </th>
      <td mat-cell *matCellDef="let element" [attr.data-timestamp]="element.lastResolutionUpdatedAt">
        <span *ngIf="element.lastResolutionUpdatedAt">{{ (element.lastResolutionUpdatedAt) | date: dateTimeFormat }} {{zoneAbbr}}</span>
        <span *ngIf="element.resolutionEditedBy" class="resolution-editedBy" (mouseover)="openPopover(popoverEdited)" (mouseleave)="closePopover(popoverEdited)">
          <span i18n="@@edited">Edited</span>
          <img class="icon" src="assets/icons/new-info.svg" alt="">
        </span>
        <div class="popover-edited" #popoverEdited>
          <p style="margin-top: 2px;"><b i18n="@@edited-by">Edited By</b>: {{ element.resolutionEditedBy }}</p>
          <p style="margin: 16px 0;">
            <b><span i18n="@@new-resolution">New Resolution</span>:</b>
            <br />{{ element.resolution }}
          </p>
          <p class="resolution-date"><b i18n="@@on">On</b> {{ (element.lastResolutionUpdatedAt) | date: dateTimeFormat }} {{zoneAbbr}}</p>
          <hr style="color: rgba(92, 110, 115, 1)">
          <p>
            <b><span i18n="@@original-resolution">Original Resolution</span>:</b>
            <br />{{ element.originalResolution }}
          </p>
          <p class="resolution-date" style="margin-bottom: 2px;"><b i18n="@@on">On</b> {{ (element.resolvedAt) | date: dateTimeFormat }} {{zoneAbbr}}</p>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
     <!-- Rows displayed when there is no data -->
    <tr class="mat-row" *matNoDataRow >
      <td class="mat-cell table-no-data-row" colspan="16" *ngIf="!isLoading">
        <div i18n="@@no-events-to-display">No events to display</div>
      </td>
    </tr>
  </table>
  <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </mat-card>
  <mat-paginator #paginator [pageSizeOptions]="[100, 10, 20]" (page)="onPageChanged($event)"></mat-paginator>
<!--  <mat-paginator #paginator-->
<!--    [pageSizeOptions]="[100, 10, 20]"-->
<!--    (page)="onPageChanged($event)"-->
<!--  ></mat-paginator>-->
</div>
</div>
